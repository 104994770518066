define('@fortawesome/pro-solid-svg-icons', ['exports'], (function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   * Copyright 2024 Fonticons, Inc.
   */
  const faSquare = {
    prefix: 'fas',
    iconName: 'square',
    icon: [448, 512, [9632, 9723, 9724, 61590], "f0c8", "M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"]
  };

  exports.faSquare = faSquare;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
