define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   * Copyright 2024 Fonticons, Inc.
   */
  const faChevronUp = {
    prefix: 'far',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", "M239 111c9.4-9.4 24.6-9.4 33.9 0L465 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-175-175L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 111z"]
  };
  const faBarsSort = {
    prefix: 'far',
    iconName: 'bars-sort',
    icon: [448, 512, [], "e0ae", "M0 88C0 74.7 10.7 64 24 64l400 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L24 112C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24l272 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L24 272c-13.3 0-24-10.7-24-24zM192 408c0 13.3-10.7 24-24 24L24 432c-13.3 0-24-10.7-24-24s10.7-24 24-24l144 0c13.3 0 24 10.7 24 24z"]
  };
  const faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };

  exports.faBarsSort = faBarsSort;
  exports.faChevronUp = faChevronUp;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
