define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   * Copyright 2024 Fonticons, Inc.
   */
  const faCirclePlus = {
    prefix: 'fal',
    iconName: 'circle-plus',
    icon: [512, 512, ["plus-circle"], "f055", "M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM240 352c0 8.8 7.2 16 16 16s16-7.2 16-16l0-80 80 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-80 0 0-80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 80-80 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l80 0 0 80z"]
  };

  exports.faCirclePlus = faCirclePlus;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
